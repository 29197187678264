<template>
  <div>
    <notifications group="notification" position="bottom right" />
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom card-sticky" id="kt_page_sticky_card">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Edit User</h3>
            </div>
            <CardToolbar
              @submitEvent="onSubmit"
              back-text="Back to list"
              back-url="/users/list"
              button-text="Edit User"
            />
          </div>
          <div class="card-body">
            <!--begin::Form-->
            <form class="form" id="brand_form">
              <div class="row">
                <div class="col-xl-2"></div>
                <div class="col-xl-8">
                  <div class="my-5">
                    <h3 class="text-dark font-weight-bold mb-10">
                      Personal Info:
                    </h3>
                    <div class="form-group row">
                      <label class="col-3">Name</label>
                      <div class="col-9">
                        <input
                          v-model="user.personalInfo.name"
                          class="form-control form-control-solid"
                          type="text"
                          placeholder="Enter user name"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-3">Surname</label>
                      <div class="col-9">
                        <input
                          v-model="user.personalInfo.surname"
                          class="form-control form-control-solid"
                          type="text"
                          placeholder="Enter user surname"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-3">Phone</label>
                      <div class="col-9">
                        <input
                          v-model="user.personalInfo.phone"
                          class="form-control form-control-solid"
                          type="text"
                          placeholder="Enter user phone"
                        />
                      </div>
                    </div>
                    <div class="separator separator-dashed my-10"></div>
                    <h3 class="text-dark font-weight-bold mb-10">
                      Account Info:
                    </h3>
                    <div class="form-group row">
                      <label class="col-3">Username</label>
                      <div class="col-9">
                        <input
                          v-model="user.accountInfo.username"
                          class="form-control form-control-solid"
                          type="text"
                          placeholder="Enter user username"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-3">Email</label>
                      <div class="col-9">
                        <input
                          v-model="user.email"
                          class="form-control form-control-solid"
                          type="text"
                          placeholder="Enter user email"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-3">Password</label>
                      <div class="col-9">
                        <input
                          v-model="user.plainPassword"
                          class="form-control form-control-solid"
                          type="password"
                          placeholder="Enter user password"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-2"></div>
              </div>
            </form>
            <!--end::Form-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import CardToolbar from '@/view/content/components/card-toolbar/CardToolbar'
import ApiService from '@/core/services/api.service'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'This field is required'
})

export default {
  name: 'EditUser',
  components: {
    CardToolbar
  },
  data() {
    return {
      user: {
        email: null,
        plainPassword: '',
        roles: null,
        personalInfo: {
          job: null,
          name: null,
          email: null,
          phone: null,
          photo: null,
          surname: null,
          company_name: null,
          company_site: null
        },
        accountInfo: {
          email: null,
          language: null,
          username: null,
          time_zone: null,
          verification: null,
          communication: {
            sms: null,
            email: null,
            phone: null
          }
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'All Users', route: '/users/list' },
      { title: 'Edit New' }
    ])

    ApiService.get(`/users`, this.$route.params.id).then(({ data }) => {
      this.user.email = data.email
      this.user.roles = data.roles
      this.user.personalInfo.job = data.personalInfo.job
      this.user.personalInfo.name = data.personalInfo.name
      this.user.personalInfo.email = data.personalInfo.email
      this.user.personalInfo.phone = data.personalInfo.phone
      this.user.personalInfo.photo = data.personalInfo.photo
      this.user.personalInfo.surname = data.personalInfo.surname
      this.user.personalInfo.company_name = data.personalInfo.company_name
      this.user.personalInfo.company_site = data.personalInfo.company_site
      this.user.accountInfo.email = data.accountInfo.email
      this.user.accountInfo.language = data.accountInfo.language
      this.user.accountInfo.username = data.accountInfo.username
      this.user.accountInfo.time_zone = data.accountInfo.time_zone
      this.user.accountInfo.verification = data.accountInfo.verification
      this.user.accountInfo.communication.sms =
        data.accountInfo.communication.sms
      this.user.accountInfo.communication.email =
        data.accountInfo.communication.email
      this.user.accountInfo.communication.phone =
        data.accountInfo.communication.phone
    })
  },
  methods: {
    async onSubmit() {
      this.updateUser(this.$route.params.id, this.user)
        .then(({ status }) => {
          if (status === 200) {
            this.$notify({
              group: 'notification',
              type: 'success',
              title: 'User successful update in database'
            })
            setTimeout(() => {
              this.$router.push({ name: 'users-list' })
            }, 500)
          }
        })
        .catch(() => {
          this.$notify({
            group: 'notification',
            type: 'error',
            title: 'User update error'
          })
        })
    },
    async updateUser(id, entity) {
      try {
        return await ApiService.update('/users', id, entity)
      } catch (e) {
        throw new Error(e)
      }
    }
  }
}
</script>
